<template>
  <Frame :width="3840" :height="2160" bgColor="">
    <!-- <dv-full-screen-container> -->
    <div class="login-container" v-if="!loginFlag" @click="showToast = false">
      <div class="login-box">
        <div class="body">
          <div class="name">admin</div>
          <div class="input" :class="showToast ? 'in-active' : 'su-active'">
            <img src="../../assets/icon-password.png" style="width: 76px; height: 76px" />
            <input type="password" v-model="password" @keydown.enter="login" />
            <div class="toast" v-show="showToast">
              <span class="tip"> {{ Tmessage }}</span>
            </div>
          </div>
          <div class="button" @click.stop="login">登录</div>
        </div>
      </div>
    </div>
    <div id="container" v-show="loginFlag">
      <!-- <dv-loading v-show="status" class="dv-loading">数据加载中</dv-loading> -->
      <div>
        <topHeader class="head" @reload="reload"></topHeader>
        <div class="body" style="padding: 0 28px">
          <myLeft ref="myLeft"></myLeft>
          <myMiddle class="my-middle" ref="myMiddle"></myMiddle>
          <myRight :names="names" :nums="nums" ref="myRight"></myRight>
        </div>
      </div>
    </div>
    <!-- </dv-full-screen-container> -->
  </Frame>
</template>

<script>
import Frame from "../../components/Frame";
import topHeader from "@/views/index/components/top-header";
import myLeft from "@/views/index/components/left";
import myMiddle from "@/views/index/components/middle";
import myRight from "@/views/index/components/right";
import http from "../../http/index";

export default {
  components: {
    Frame,
    topHeader,
    myLeft,
    myMiddle,
    myRight,
  },
  computed: {},
  data() {
    return {
      status: false,
      names: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      nums: [1233, 2123, 556, 1232, 1223, 123, 231],
      times: null,
      socketWorkorder: null,
      socketOrder: null,
      list: [],
      loginFlag: false,
      password: "",
      Tmessage: "",
      showToast: false,
      freshGroup: {},
      freshSir: {},
      housekeeping: {},
      visitsList: {},
      orderList: [],
    };
  },
  mounted() {
    this.initWebSocket();
    this.init();
    this.initBase();
  },
  methods: {
    reload() {
      this.init();
      this.initBase();
    },
    //右侧图表数据
    async init() {
      this.status = true;
      let res1 = await http.GetChartData();
      // console.log(res);
      //salesData 排行版
      //xturnoverData x轴  yturnoverData y轴
      this.$refs.myRight.initEchats(res1);
      this.$refs.myLeft.initA(res1);
      this.status = false;
      let res2 = await http.GetBaseData();
      this.$refs.myLeft.initVisit(res2);
      this.$refs.myMiddle.init(res2);
    },
    async login() {
      this.showToast = false;
      // console.log('1');
      if (!this.password) {
        this.Tmessage = "请输入密码";
        this.showToast = true;
        //  console.log('122');
        return;
      }
      //  console.log('1333');
      let res = await http.login({ password: this.password });
      if (res == "1") {
        this.loginFlag = true;
        // console.log('44444');
      } else {
        this.Tmessage = "密码错误";
        // console.log('55555');
        this.showToast = true;
      }
    },
    // 今日工单数据初始化
    async initBase() {
      const res = await http.GetWorkOrderData();
      this.$refs.myLeft.init("", res);
    },
    initWebSocket() {
      this.getSocketWorkOrder();
      this.getSocketOrder();
      let that = this;
      this.times = setInterval(() => {
        try {
          that.socketWorkorder.send("心跳workOrder");
          that.socketOrder.send("心跳order");
        } catch (err) {
          // that.closeSocket()
        }
      }, 3000);
    },
    // 长链接
    getSocketWorkOrder() {
      this.socketWorkorder = new WebSocket(
        "wss://bi.youxianxin.cn/api/webSocket/workorder"
        // "ws://192.168.140:8085/webSocket/workorder"
      );
      let that = this;
      this.socketWorkorder.onopen = function () {
        // that.socket.send('心跳');
        console.log("getSocketworkOrder握手成功!");
      };
      this.socketWorkorder.onmessage = function (e) {
        // console.log(e);
        try {
          if (e.data != null && e.data != "") {
            if (e.data != "心跳workOrder") {
              let res = JSON.parse(e.data);
              // console.log(res);
              if (res.isNeedRefresh == 1) {
                that.$router.go(0);
              } else {
                that.list.unshift(res.newInfo);
                that.$refs.myLeft.init(that.list, res.workOrderData);
                that.list = that.list.slice(0, 4);
              }
            }
          }
        } catch (err) {
          // console.log(err);
          // that.closeSocket();
        }
      };
    },
    getSocketOrder() {
      this.socketOrder = new WebSocket(
        "wss://bi.youxianxin.cn/api/webSocket/order"
        // "ws://192.168.140:8085/webSocket/order"
      );
      let that = this;
      this.socketOrder.onopen = function () {
        console.log("getSocketorder握手成功!");
      };
      this.socketOrder.onmessage = function (e) {
        // console.log(e);
        try {
          if (e.data != null && e.data != "") {
            if (e.data != "心跳order") {
              let res = JSON.parse(e.data);
              // console.log(res);
              that.orderList.unshift(res.newInfo);
              that.$refs.myMiddle.initOrder(that.orderList, res);
              that.orderList = that.orderList.slice(0, 4);
            }
          }
        } catch (err) {
          console.log(err);
          // that.closeSocket();
        }
      };
    },
    closeSocket() {
      if (this.socketWorkorder) {
        this.socketWorkorder.close();
        let _this = this;
        this.socketWorkorderws.onclose = function (evt) {
          console.log("websocket已关闭");
        };
      }
      if (this.socketOrder) {
        this.socketOrder.close();
        let _this = this;
        this.socketOrder.onclose = function (evt) {
          console.log("websocket已关闭");
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
#container {
  // background: linear-gradient(158deg, #001e40 0%, #000d1e 100%);
  background: rgb(2, 8, 28);
  // width: 100%;
  height: 100%;
  // padding: 0 28px;
  .body {
    display: flex;
    margin-top: -20px;
  }
}
.dv-loading {
  // margin: 1000px auto;
  color: #fff;
}

.my-middle {
  width: 1800px;
  margin: 45px 100px 0 100px;
}
.login-container {
  background: linear-gradient(145deg, #070a49 0%, #090928 100%);
  padding-top: 240px;
  position: relative;
  .login-box {
    width: 2908px;
    height: 1548px;
    margin: 0 auto;
    background-image: url("../../assets/login-box.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    .body {
      color: #fff;
      // width: 1262px;
      height: 900px;
      // padding: 200px;
      background-image: url("../../assets/login-body.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 260px 240px;
      .name {
        width: 270px;
        height: 270px;
        background: linear-gradient(180deg, #6100d9 0%, #0057ef 100%);
        border-radius: 50%;
        text-align: center;
        font-size: 60px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 270px;
      }
      .input {
        width: 1030px;
        height: 160px;
        background: #100a43;
        border-radius: 8px;
        margin-top: 132px;
        display: flex;
        align-items: center;
        padding: 0px 60px;
        position: relative;
        box-sizing: border-box;
        input {
          outline: none;
          margin-left: 50px;
          border: none;
          background: transparent;
          width: 600px;
          font-size: 60px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .su-active {
        box-shadow: 0px 4px 42px 10px rgba(0, 160, 248, 0.56);
        border: 2px solid #00a0f8;
      }
      .in-active {
        box-shadow: 0px 4px 42px 10px rgba(255, 51, 74, 0.56);
        border-radius: 8px;
        border: 2px solid #ff334a;
      }
      .button {
        margin-top: 138px;
        width: 1030px;
        height: 160px;
        background: #192ce3;
        border-radius: 8px;
        text-align: center;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 160px;
      }
    }
  }
}
.toast {
  position: absolute;
  top: 160px;

  height: 100px;
  z-index: 100;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 100px;
  .tip {
    color: #ff334a;
    font-size: 28px;
  }
}
</style>
