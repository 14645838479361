<template>
  <div id="top-header">
    <div class="header">
      智慧物业运营综合数据大屏
    </div>
    
    <div class="fullScreen" @click="changeFullScreen">
      <div v-show="fullscreen">
        <img src="../../../assets/icon-infull.png" alt="" />
        <span> {{ fullName }}</span>
      </div>
      <div v-show="!fullscreen">
        <img src="../../../assets/icon-full.png" alt="" />
        <span> {{ fullName }}</span>
      </div>
    </div>
    <div class="week">
      <div>{{ nowdate }}</div>
      <div class="time">{{ nowtime }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "TopHeader",
  data() {
    return {
      date: "",
      time: "",
      nowdate: "", //日期
      nowtime: "", //时间
      newTimer: "", //定时器
      weekArr: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      fullscreen: false,
      fullName: "全屏",
      week:''
    };
  },
  created() {
    // moment.locale("zh-cn");
    // this.date = moment().format("YYYY-MM-DD dddd");
    // this.time = moment().format("HH:ss");
    // console.log(this.date);
  },
  methods: {
    //获取时间
    timerOneScondRun() {
       let date = new Date();
      // 日期格式  2022-08-25 星期四
      this.nowdate = moment().format("YYYY-MM-DD") +  '      ' +  this.weekArr[date.getDay()];
      // 时间格式 12:11:27
      // this.week = this.weekArr[date.getDay()];
      this.nowtime = moment().format("HH:mm:ss") 
      const now = new Date();
      const nowTime = new Date().getTime(); //时间戳
      const HourTime = moment().format("mm:ss"); //整点时间
       //整点刷新
      if (this.nowtime == "00:00:00") {
        this.$emit("reload", "1");
      }
    },
    changeFullScreen() {
      const element = document.documentElement;
      // 如果是全屏状态
      if (this.fullscreen) {
        // 如果浏览器有这个Function
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 如果浏览器有这个Function
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      // 判断全屏状态的变量
      this.fullscreen = !this.fullscreen;
      this.fullName = this.fullscreen ? "退出全屏" : "全屏";
    },
    
  },
  mounted() {
    this.timerOneScondRun(); //执行日期函数
    clearInterval(this.newTimer); //清除定时器
    // 定时获取时间
    this.newTimer = setInterval(this.timerOneScondRun, 1000);
  },
  beforeRouteLeave(to, from, next) {
    // 离开组件时停掉定时器，节省内存
    clearInterval(this.newTimer);
    next();
  },
};
</script>

<style lang="less" scoped>
#top-header {
  position: relative;
  // padding-top: 80px;
  display: flex;
  height: 182px;
  justify-content: space-between;
  .header{
    background-image: url('../../../assets/header.gif');
    background-size: 100% 100%;
    width: 100%;
    height: 200px;
    font-family: YouSheBiaoTiHei;
    color: #FFFFFF;
    line-height: 120px;
    letter-spacing: 4px;
    font-size: 70px;
    text-align: center;
    padding-right: 60px;
  }
  .mid {
    width: 40%;
  }
  .left,
  .right {
    width: 26%;
  }
  .title {
    position: absolute;
    left: 50%;
    top: 60px;
    width: 900px;
    height: 108px;
    font-size: 72px;
    transform: translateX(-50%);
    text-align: center;
    font-family: Tensentype-LingHeiJ, Tensentype;
    font-weight: normal;
    color: #f5f7ff;
    line-height: 86px;
    text-shadow: 0px 0px 12px #0055ff;
    background: linear-gradient(180deg, rgba(2, 42, 96, 0) 0%, #0078dc 100%);
  }
  .fullScreen {
    position: absolute;
    font-size: 32px;
    top: 95px;
    right: 40px;
    color: #fff;
    div {
      display: flex;
      // align-items: center;
      line-height:62px;
      img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
    }
  }
  .week {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 50px;
    // width: 382px;
    height: 40px;
    top: 115px;
    color: #ffffff;
    font-size: 28px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    .time {
      margin-left: 24px;
      font-family: Tensentype-LingHeiJ, Tensentype;
      text-shadow: 0px 0px 12px #0055ff;
      font-size: 40px;
      height: 48px;
    }
  }
}
</style>
