<template>
  <div class="container3">
    <div class="c-title">优鲜团</div>
    <div class="item-charts" style="">
      <div v-show="activeFlag" v-cloak>
        <VueEcharts
          :options="chartOptionA"
          :chartStyle="{ width: '950px', height: '495px' }"
        />
      </div>
      <div v-show="!activeFlag" class="top-container">
        <div v-for="(item, index) in configA" :key="item[0]" class="item">
          <span class="num1 text-ellipsis">{{ item[0] }}</span>
          <div class="num2">
            <div
              :class="index == 0 ? 'numbg1' : 'numbg2'"
              :style="{
                width: (item[1] / configA[0][1]) * 500 + 'px',
              }"
            ></div>
            <span
              class="num3"
              :style="{
                color: index == 0 ? '#F2E817' : '#0BD6DD ',
              }"
            >
              ￥{{ formatNumber(item[1]) }}</span
            >
          </div>
        </div>
      </div>
      <div class="sub-title-container">
        <div class="title">{{activeFlag?'近15天营业额变化':'近15天提货点销售额TOP6'}}</div>
        <div style="display: flex">
          <div class="sub-title" :class="activeFlag ? 'active' : ''">
            总营业额
          </div>
          <div class="sub-title" :class="activeFlag ? '' : 'active'">
            提货点销售额排行榜
          </div>
        </div>
      </div>
    </div>
    <div class="c-title">优鲜生</div>
    <div class="item-charts" style="">
      <div v-show="activeFlag" v-cloak>
        <VueEcharts
          :options="chartOptionB"
          :chartStyle="{ width: '950px', height: '495px' }"
        />
      </div>
      <div v-show="!activeFlag" class="top-container">
        <div v-for="(item, index) in configB" :key="item[0]" class="item">
          <span class="num1 text-ellipsis">{{ item[0] }}</span>
          <div class="num2">
            <div
              :class="index == 0 ? 'numbg1' : 'numbg2'"
              :style="{
                width: (item[1] / configB[0][1]) * 500 + 'px',
              }"
            ></div>
            <span
              class="num3"
              :style="{
                color: index == 0 ? '#F2E817' : '#0BD6DD ',
              }"
            >
             ￥{{ formatNumber(item[1]) }}</span
            >
          </div>
        </div>
      </div>
      <div class="sub-title-container">
         <div class="title">{{activeFlag?'近15天营业额变化':'近15天商家销售额TOP6'}}</div>
        <div style="display: flex">
          <div class="sub-title" :class="activeFlag ? 'active' : ''">
            总营业额
          </div>
          <div class="sub-title" :class="activeFlag ? '' : 'active'">
            门店销售额排行榜
          </div>
        </div>
      </div>
    </div>
    <div class="c-title">家政维修</div>
    <div class="item-charts" style="">
      <div v-show="activeFlag">
        <VueEcharts
          v-cloak
          :options="chartOptionC"
          :chartStyle="{ width: '950px', height: '495px' }"
        />
      </div>
      <div v-show="!activeFlag" class="top-container">
        <div v-for="(item, index) in configC" :key="item[0]" class="item">
          <span class="num1 text-ellipsis">{{ item[0] }}</span>
          <div class="num2">
            <div
              :class="index == 0 ? 'numbg1' : 'numbg2'"
              :style="{
                width: (item[1] / configC[0][1]) * 500 + 'px',
              }"
            ></div>
            <span
              class="num3"
              :style="{
                color: index == 0 ? '#F2E817' : '#0BD6DD ',
              }"
            >
             ￥{{ formatNumber(item[1]) }}</span
            >
          </div>
        </div>
      </div>

      <div class="sub-title-container">
        <div class="title">{{activeFlag?'近15天营业额变化':'近15天商家销售额TOP6'}}</div>
        <div style="display: flex">
          <div class="sub-title" :class="activeFlag ? 'active' : ''">
            总营业额
          </div>
          <div class="sub-title" :class="activeFlag ? '' : 'active'">
            商家销售额排行榜
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CountTo from "vue-count-to";
import VueEcharts from "../../../components/echartsBase/VueEcharts.vue";
let opt = {
  grid: {
    left: "15%",
  },
  // animationDuration: 0, //这里两个动画设置可以让图表更顺滑
  // animationEasing: "cubicInOut", //这里两个动画设置可以让图表更顺滑,
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisTick: {
      show: true, // 是否显示坐标轴刻度
      inside: true, // 坐标轴刻度是否朝内，默认朝外
      length: 1, // 坐标轴刻度的长度
      lineStyle: {
        color: "#617592", // 刻度线的颜色
        width: 6,
        type: "dotted", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
        cap: "round",
      },
    },
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    axisLabel: {
      show: true, // 是否显示刻度标签
      color: "#617592", // 刻度标签文字的颜色
      fontSize: "24", // 文字字体大小
      margin: 30, // 刻度标签与轴线之间的距离
    },
  },
  yAxis: {
    type: "value",
    /* 改变y轴颜色 */
    axisLine: {
      show: true,
      lineStyle: {
        color: "#064A6B",
        width: 2, // 这里是为了突出显示加上的
      },
    },
    axisLabel: {
      show: true, // 是否显示刻度标签
      color: "#617592", // 刻度标签文字的颜色
      verticalAlign: "left", // 文字垂直对齐方式，默认自动（'top'，'middle'，'bottom'
      margin: 20,
      fontSize: "24", // 文字字体大小
    },
    axisTick: {
      show: true, // 是否显示坐标轴刻度
      length: 10, // 坐标轴刻度的长度
      lineStyle: {
        color: "#3989DF", // 刻度线的颜色
        width: 1, // 坐标轴刻度线宽
        type: "solid", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
      },
    },
    splitLine: {
      lineStyle: {
        // 设置背景横线
        color: "#03314C",
      },
    },
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 20],
      type: "line",
      symbolSize: 10, // 折线点的大小
      itemStyle: {
        color: "#02CFFD", // 折线点的颜色
        lineStyle: {
          color: "#02CFFD", // 折线的颜色
        },
        // normal: {
        //   // 拐点上显示数值
        //   label: {
        //     show: false,
        //   },
        //   fontSize: "24",
        // },
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(58, 137, 223, 0.5)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(0,16,36,0)", //   100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      smooth: true, //平滑曲线
    },
  ],
};

export default {
  name: "myRight",
  components: {
    CountTo,
    VueEcharts,
  },
  props: {
    names: {
      type: Array,
    },
    nums: {
      type: Array,
    },
  },
  data() {
    return {
      newTimer: null,
      activeFlag: false,
      date: "",
      baseNum: "",
      configA: [],
      configB: [],
      configC: [],
      num: [],
      chartOptionA: {},
      chartOptionB: {},
      chartOptionC: {},
    };
  },
  created() {},
  computed: {
    chartOption() {
      opt.xAxis.data = this.names;
      opt.series[0].data = this.num;
      return Object.assign({}, opt);
    },
  },
  mounted() {
    this.timeInt(); //切换 图表 排行
    clearInterval(this.newTimer); //清除定时器
    // 定时获取时间
    this.newTimer = setInterval(this.timeInt, 30000);
  },
  beforeRouteLeave(to, from, next) {
    // 离开组件时停掉定时器，节省内存
    clearInterval(this.newTimer);
    next();
  },
  methods: {
    //切换
    timeInt() {
      this.activeFlag = !this.activeFlag;
    },
    initA(data) {
      let temp = JSON.parse(JSON.stringify(opt));
      this.initS(data.salesData, "A");
      temp.series[0].itemStyle.color = "#FFDF0A";
      temp.series[0].itemStyle.lineStyle.color = "#FFDF0A";
      temp.series[0].areaStyle.color.colorStops[0].color = "#FAB900";
      temp.xAxis.data = data.xturnoverData;
      temp.series[0].data = data.yturnoverData;
      this.chartOptionA = Object.assign({}, temp);
    },
    initB(data) {
      let temp = JSON.parse(JSON.stringify(opt));
      this.initS(data.salesData, "B");
      temp.series[0].itemStyle.color = " #00C4FF";
      temp.series[0].itemStyle.lineStyle.color = "#00C4FF ";
      temp.series[0].areaStyle.color.colorStops[0].color = " #00C3FF";
      temp.xAxis.data = data.xturnoverData;
      temp.series[0].data = data.yturnoverData;
      this.chartOptionB = Object.assign({}, temp);
    },
    initC(data) {
      let temp = JSON.parse(JSON.stringify(opt));
      this.initS(data.salesData, "C");
      temp.series[0].itemStyle.color = "#0090FF";
      temp.series[0].itemStyle.lineStyle.color = "#0090FF";
      temp.series[0].areaStyle.color.colorStops[0].color = "#0090FF";
      temp.xAxis.data = data.xturnoverData;
      temp.series[0].data = data.yturnoverData;
      this.chartOptionC = Object.assign({}, temp);
    },
    //echarts
    initEchats(data) {
      this.initA(data.freshGroup); //优鲜团
      this.initB(data.freshSir); //优鲜生
      this.initC(data.housekeeping); //家政维修
    },
    //初始化数据
    initS(data, name) {
      if (data && data.length > 0) {
        // this.$nextTick(() => {
          this[`config${name}`] = data.slice(0, 6);
        // });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container3 {
  width: 974px;
  color: #fff;
}
.item-charts {
  width: 950px;
  height: 495px;
  position: relative;
  .sub-title-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: -5px;
    left: 20px;
    // right: 30px;
    height: 44px;
    width: 880px;
    .title {
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
    }
    .sub-title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #eef6ff;
      padding: 2px 16px 4px 8px;
      margin-right: 14px;
      box-sizing: border-box;
      background: #000d1e;
      border: 2px solid #08336a;
    }
    .active {
      background: #01418e;
      border: 2px solid #247ef3;
    }
  }
}
.top-container {
  width: 900px;
  padding-top: 80px;
  height: 600px;
  display: flex;
  flex-direction: column;
  // background: red;
  padding-left: 20px;
  .item {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    margin: 6px 0;
    .num1 {
      width: 180px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #8ca5c8;
      line-height: 55px;
      letter-spacing: 6px;
      
    }
    .num2 {
      display: flex;
      align-items: center;
      margin-left: 18px;
    }
    .num3 {
      line-height: 55px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f2e817;
      margin-left: 18px;
    }
    .numbg1 {
      background: linear-gradient(243deg, #f2eb16 0%, #cd3531 100%) ;
      border-radius: 14px ;
      height: 26px ;
      // animation: animate-positive 2s; 

    }
    .numbg2 {
      background: linear-gradient(243deg, #0ad8dd 0%, #2c9ae2 100%);
      border-radius: 14px ;
      height: 26px ;
      // animation: animate-positive 2s; 
    }
  }
}
[v-cloak] {
  display: none !important;
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@-ms-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@-moz-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@keyframes animate-positive {
  0% {
    width: 0%;
  }
}
</style>