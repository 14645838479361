<template>
  <div >
    <div :id="randomId" :style="chartStyle"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'vue-echarts',
  props: {
    options: {
      type: Object,
      required: true
    },
    chartStyle:{
      type:Object
    }
  },
  data()
  {
    return{
      myChart:null,
      randomId:'charts'+Math.random()
    }
  },
  watch: {
    options (newValue, oldValue) {
      if (this.myChart && newValue) {
        this.myChart.clear()
        this.myChart.setOption(newValue,true)
      
      }
    }
  },
  methods: {
    init () {
      if (!this.myChart) {
       
        this.myChart = echarts.init(document.getElementById(this.randomId))
      }
      this.myChart.setOption(this.options,true)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>

</style>
