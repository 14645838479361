<template>
  <div class="container1">
    <div class="c-title animate__pulse">用户数据统计</div>
    <div class="data-container">
      <div class="item">
        <div class="box-title"><div class="title">今日</div></div>
        <div class="box-span">
          <span>访问人数</span>
          <span class="num">
            <count-to
              :startVal="oldVisitData.todayVisits"
              :endVal="visitData.todayVisits"
              :duration="1000"
            ></count-to>
          </span>
        </div>
        <div class="box-span">
          <span>新用户数</span
          ><span class="num">
            <count-to
              :startVal="oldVisitData.todayNewUsers"
              :endVal="visitData.todayNewUsers"
              :duration="1000"
            ></count-to
          ></span>
        </div>
        <div class="box-total">
          <div class="bor border-left"></div>
          <div class="bor border-top"></div>
          <div class="bor border-right"></div>
          <div class="bor border-bottom"></div>
          <div class="title">
            <count-to
              :startVal="oldVisitData.totalUsers"
              :endVal="visitData.totalUsers"
              :duration="1000"
            ></count-to>
          </div>
          <div class="sub-title">总用户数</div>
        </div>
      </div>
      <div class="item">
        <div class="box-title"><div class="title2">昨日</div></div>
        <div class="box-span2">
          <span>访问人数</span>
          <span class="num">
            <count-to
              :startVal="oldVisitData.yesterdayVisits"
              :endVal="visitData.yesterdayVisits"
              :duration="1000"
            ></count-to
          ></span>
        </div>
        <div class="box-span2">
          <span>新用户数</span
          ><span class="num">
            <count-to
              :startVal="oldVisitData.yesterdayNewUsers"
              :endVal="visitData.yesterdayNewUsers"
              :duration="1000"
            ></count-to
          ></span>
        </div>
        <div class="box-total">
          <div class="bor border-left"></div>
          <div class="bor border-top"></div>
          <div class="bor border-right"></div>
          <div class="bor border-bottom"></div>
          <div class="title">
            <count-to
              :startVal="oldVisitData.activity"
              :endVal="visitData.activity"
              :duration="1000"
            ></count-to
            >%
          </div>
          <div class="sub-title">活跃度</div>
        </div>
      </div>
    </div>
    <div class="charts1" style="width: 900px; height: 495px">
      <VueEcharts
        :options="chartOptionA"
        :chartStyle="{ width: '1000px', height: '495px' }"
      />
    </div>
    <div class="today-data">
      <div class="c-title">今日工单数据</div>
      <div class="c-container" style="">
        <div class="order-item">
          <div class="left">
            <img src="../../../assets/left1.png" alt="" class="image" />
          </div>
          <div class="right">
            <span class="title">新增工单</span>
            <span class="sub-title">
              <count-to
                :startVal="oldOrderData.newOrders"
                :endVal="workOrderData.newOrders"
                :duration="500"
              ></count-to>
            </span>
          </div>
        </div>
        <div class="order-item">
          <div class="left" style="border: 2px solid #875a00">
            <img src="../../../assets/left2.png" alt="" class="image" />
          </div>
          <div class="right">
            <span class="title">待处理</span>
            <span class="sub-title">
              <count-to
                :startVal="oldOrderData.noProcessingOrders"
                :endVal="workOrderData.noProcessingOrders"
                :duration="500"
              ></count-to
            ></span>
          </div>
        </div>
        <div class="order-item">
          <div class="left" style="border: 2px solid #7c5500">
            <img src="../../../assets/left3.png" alt="" class="image" />
          </div>
          <div class="right">
            <span class="title">处理中</span>
            <span class="sub-title">
              <count-to
                :startVal="oldOrderData.processingOrders"
                :endVal="workOrderData.processingOrders"
                :duration="500"
              ></count-to>
            </span>
          </div>
        </div>
        <div class="order-item">
          <div class="left" style="border: 2px solid #fb3a4d">
            <img src="../../../assets/left4.png" alt="" class="image" />
          </div>
          <div class="right">
            <span class="title">超时工单</span>
            <span class="sub-title">
              <count-to
                :startVal="oldOrderData.timeOutOrders"
                :endVal="workOrderData.timeOutOrders"
                :duration="1000"
              ></count-to>
            </span>
          </div>
        </div>
        <div class="order-item">
          <div class="left" style="border: 2px solid #fb3a4d">
            <img src="../../../assets/left5.png" alt="" class="image" />
          </div>
          <div class="right">
            <span class="title">不满意工单</span>
            <span class="sub-title">
              <count-to
                :startVal="oldOrderData.dissatisfiedOrders"
                :endVal="workOrderData.dissatisfiedOrders"
                :duration="500"
              ></count-to>
            </span>
          </div>
        </div>
        <div class="order-item">
          <div class="left" style="border: 2px solid #696969">
            <img src="../../../assets/left6.png" alt="" class="image" />
          </div>
          <div class="right">
            <span class="title">关闭工单</span>
            <span class="sub-title">
              <count-to
                :startVal="oldOrderData.closeOrders"
                :endVal="workOrderData.closeOrders"
                :duration="500"
              ></count-to>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="new-order">
      <div class="d-title">工单最新动态</div>
      <div class="my-box" style="">
        <div v-for="item in list" :key="item[0]" class="table-item animation-d">
          <div class="text-ellipsis item1">{{ item[0] }}</div>
          <div class="text-ellipsis item2">{{ item[1] }}</div>
          <div class="text-ellipsis item3">{{ item[2] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CountTo from "vue-count-to";
import VueEcharts from "../../../components/echartsBase/VueEcharts.vue";
import http from "../../../http/index.js";
let opt = {
  grid: {
    left: "13%",
  },
  title: {
    text: "近7天访问人数",
    textStyle: {
      fontSize: 24,
      color: "#fff",
      fontFamily: "PingFangSC-Medium, PingFang SC",
      fontWeight: 500,
    },
    padding:[0,0,0,20],
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisTick: {
      show: true, // 是否显示坐标轴刻度
      inside: true, // 坐标轴刻度是否朝内，默认朝外
      length: 1, // 坐标轴刻度的长度
      lineStyle: {
        color: "#617592", // 刻度线的颜色
        width: 6,
        type: "dotted", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
        cap: "round",
      },
    },
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    axisLabel: {
      show: true, // 是否显示刻度标签
      color: "#617592", // 刻度标签文字的颜色
      fontSize: "24", // 文字字体大小
      margin: 30, // 刻度标签与轴线之间的距离
    },
  },
  yAxis: {
    type: "value",
    /* 改变y轴颜色 */
    axisLine: {
      show: true,
      lineStyle: {
        color: "#064A6B",
        width: 2, // 这里是为了突出显示加上的
      },
      textStyle: {
        fontSize: "24",
        // color: "red",
      },
    },
    axisLabel: {
      show: true, // 是否显示刻度标签
      color: "#617592", // 刻度标签文字的颜色
      verticalAlign: "left", // 文字垂直对齐方式，默认自动（'top'，'middle'，'bottom'
      margin: 20,
      fontSize: "24", // 文字字体大小
    },
    axisTick: {
      show: true, // 是否显示坐标轴刻度
      length: 10, // 坐标轴刻度的长度
      lineStyle: {
        color: "#3989DF", // 刻度线的颜色
        width: 1, // 坐标轴刻度线宽
        type: "solid", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
      },
    },
    splitLine: {
      lineStyle: {
        // 设置背景横线
        color: "#03314C",
      },
    },
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: "line",
      symbol: "circle", // 折线点设置为实心点
      symbolSize: 10, // 折线点的大小
      itemStyle: {
        color: "#02CFFD", // 折线点的颜色
        lineStyle: {
          color: "#02CFFD", // 折线的颜色
        },
      },
      areaStyle: {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(58, 137, 223, 0.5)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(2, 207, 253, 0)", //   100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      smooth: true, //平滑曲线
    },
  ],
};
export default {
  name: "myLeft",
  components: {
    CountTo,
    VueEcharts,
  },
  data() {
    return {
      date: "",
      list: [],
      workOrderData: {
        closeOrders: 0,
        dissatisfiedOrders: 0,
        newOrders: 0,
        noProcessingOrders: 0,
        processingOrders: 0,
        timeOutOrders: 0,
      },
      oldOrderData: {
        closeOrders: 0,
        dissatisfiedOrders: 0,
        newOrders: 0,
        noProcessingOrders: 0,
        processingOrders: 0,
        timeOutOrders: 0,
      },
      visitData: {
        todayVisits: 0, //今日访问人数
        todayNewUsers: 0, //今日新用户数
        yesterdayVisits: 0, //昨日访问人数
        yesterdayNewUsers: 0, //昨日新用户数
        totalUsers: 0, //总用户数
        activity: 0, //活跃度：系统中登录过两次及两次以上的用户 / 总用户数 （没有就直接传0）
      },
      oldVisitData: {
        todayVisits: 0, //今日访问人数
        todayNewUsers: 0, //今日新用户数
        yesterdayVisits: 0, //昨日访问人数
        yesterdayNewUsers: 0, //昨日新用户数
        totalUsers: 0, //总用户数
        activity: 0, //活跃度：系统中登录过两次及两次以上的用户 / 总用户数 （没有就直接传0）
      },
      chartOptionA: {},
      newTimer: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    initA(data) {
      let temp = JSON.parse(JSON.stringify(opt));
      temp.xAxis.data = data.xdata;
      temp.series[0].data = data.ydata;
      this.chartOptionA = Object.assign({}, temp);
    },
    //工单数据
    init(list, workOrderData) {
      if (list) {
        this.list = list.slice(0, 4);
      }
      if (workOrderData) {
        this.oldOrderData = this.workOrderData;
        this.workOrderData = workOrderData;
      }
    },
    //定时查用户数
    async tiemVisit() {
      // console.log("11tiemVisit");
      const res = await http.GetUserStatistics();
      this.oldVisitData.todayVisits = this.visitData.todayVisits;
      this.visitData.todayVisits = res.todayVisits;

      this.oldVisitData.todayNewUsers = this.visitData.todayNewUsers;
      this.visitData.todayNewUsers = res.todayNewUsers;

      this.oldVisitData.totalUsers = this.visitData.totalUsers;
      this.visitData.totalUsers = res.totalUsers;
      
      this.oldVisitData.activity = this.visitData.activity;
      this.visitData.activity = res.activity;
    },
    //访客数据
    initVisit(data) {
      this.oldVisitData = this.visitData;
      this.visitData = data;
      //初值服完在定时
      this.tiemVisit(); //执行日期函数
      clearInterval(this.newTimer); //清除定时器
      // 定时获取时间
      this.newTimer = setInterval(this.tiemVisit, 180000);
    },
  },
  computed: {
    chartOption() {
      const names = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      const nums = ["1233", "2123", "556", "12323", "1223", "123", "231"];
      opt.xAxis.data = names;
      opt.series[0].data = nums;
      return Object.assign({}, opt);
    },
  },
  beforeRouteLeave(to, from, next) {
    // 离开组件时停掉定时器，节省内存
    clearInterval(this.newTimer);
    next();
  },
};
</script>

<style lang="less" scoped>
.container1 {
  width: 900px;
  color: #fff;
}
.data-container {
  margin:0 20px;
  // width: 100%;
  display: flex;
  height: 480px;
  // background: red;
  justify-content: space-between;
  .item {
    width: 404px;
    height: 200px;
    .box-title {
      height: 56px;
      width: 86px;
      margin-bottom: 16px;
      position: relative;
      .title,
      .title2 {
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        position: absolute;
        z-index: 11;
      }
      .title::after,
      .title2::after {
        content: "";
        position: absolute;
        right: -10px;
        bottom: 0;
        width: 74px;
        height: 28px;
        z-index: -1;
        background: linear-gradient(270deg, #0046ab 0%, #000e20 100%);
      }
      .title2::after {
        background: linear-gradient(270deg, #ba9852 0%, #000e20 100%);
      }
    }
    .box-span,
    .box-span2 {
      position: relative;
      margin-top: 28px;
      height: 54px;
      width: 334px;
      margin-left: 10px;
      margin-right: 70px;
      padding: 0 16px 0 44px;
      background: linear-gradient(270deg, #0e3e72 0%, #001832 100%);
      display: flex;
      justify-content: space-between;
      line-height: 54px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #798da9;
      .num {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
    .box-span2 {
      background: linear-gradient(270deg, #463800 0%, #001832 100%);
    }
    .box-span::after,
    .box-span2::after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      background: #48dbff;
      border-radius: 50%;
      box-shadow: 0px 0px 14px 2px rgba(0, 105, 255, 1);
      left: 12px;
      top: 20px;
    }
    .box-span2::after {
      background: #f4ebb2;
      box-shadow: 0px 0px 14px 2px rgba(230, 182, 31, 1);
    }
    .box-total::after,
    .box-total::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 52px;
      background: #48dbff;
    }
    .box-total::before {
      right: 0;
    }
    .box-total {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      // width: 414px;
      height: 190px;
      background: rgba(0, 41, 95, 0.3);
      position: relative;

      .title {
        font-size: 60px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #02cffd;
        height: 84px;
      }
      .sub-title {
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #798da9;
        line-height: 40px;
      }
      .bor {
        z-index: 15;
        position: absolute;
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0);
        border: 2px solid #064a6b;
      }
      .border-left {
        top: 0px;
        left: 0px;
        border-right: none;
        border-bottom: none;
      }
      .border-top {
        top: 0px;
        right: 0px;
        border-left: none;
        border-bottom: none;
      }
      .border-bottom {
        bottom: 0px;
        left: 0px;
        border-right: none;
        border-top: none;
      }
      .border-right {
        bottom: 0px;
        right: 0px;
        border-left: none;
        border-top: none;
      }
    }
  }
}
.today-data {
  width: 900px;
  height: 435px;
  .c-container {
   margin-left: 20px;
    width: 850px;
    // background: red;
    display: flex;
    flex-wrap: wrap;
    height: 288px;
  }
  .order-item {
    display: flex;
    align-items: center;
    width: 33%;
    height: 96px;
    .left {
      width: 82px;
      height: 82px;
      border-radius: 4px;
      border: 2px solid #0194ff;
      .image {
        width: 46px;
        height: 46px;
        margin: 24px 18px 12px 18px;
      }
    }

    .right {
      margin-left: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 96px;
      .title {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d0e3fc;
      }
      .sub-title {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
}

.new-order {
  width: 98%;
  height: 400px;
  margin-left: 20px;
  .my-box {
    margin-left: 10px;
    margin-top: 32px;
    background: linear-gradient(158deg, #001e40 0%, #000d1e 100%);
    border: 2px solid rgba(62, 155, 255, 0.16);
    // width: 838px;
    // height: 200px;
    height: 244px;
    font-size: 28px;
    color: #d0e3fc;
    padding-top: 28px;

    .table-item {
      display: flex;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d0e3fc;
      margin: 0 34px 20px 24px;
      // animation: fadeInDown 0.5s;
    }
    .item1 {
      width: 115px;
      height: 40px;
    }
    .item2 {
      width: 200px;
    }
    .item3 {
      flex: 1;
    }
  }
}
</style>
