import axios from "axios";
axios.defaults.baseURL = "https://bi.youxianxin.cn/api/";
// axios.defaults.baseURL = "http://localhost:8080/";
axios.interceptors.response.use(
  function (response) {
    if (response.status == 200) {
      const { code, result, msg } = response.data;
      if (code == 200) {
        return result;
      }
      return Promise.reject(msg);
    } else {
      return Promise.reject("请求失败");
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default {
  //https://console-docs.apipost.cn/preview/69513e689ad6fbf6/7ed790d6d5ff75db
  // 请求当前时间
  GetTime() {
    return axios.post("/index/getDate");
  },
  //获取工单数据
  GetWorkOrderData() {
    return axios.get("/big_screen/getWorkOrderData");
  },
  login(data) {
    return axios.post("/big_screen/login", data);
  },
  //大屏 图表数据接口
  GetChartData() {
    return axios.get("/big_screen/getChartData");
  },
  //订单 金额基础数据
  GetBaseData() {
    return axios.get("/big_screen/getComprehensiveData");
  },
  //获取 用户统计数据（定时刷新用户数据使用）
  GetUserStatistics() {
    return axios.get("/big_screen/getUserStatistics");
  },
};
