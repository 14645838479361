<template>
  <div class="container2">
    <div class="c1" v-cloak>
      <!-- <img
        src="https://fresh-life.oss-cn-shanghai.aliyuncs.com/app/largeScreen/bg.gif"
        alt=""
        class="image"
        v-cloak
      /> -->
      <!-- <img
        src="../../../assets/c-title-bg.png"
        alt=""
        class="image"
        v-cloak
      /> -->
      <div
        class="main-item"
        :class="index % 2 == 0 && index != 6 ? 'right' : ''"
        v-for="(item, index) in list"
        :key="index"
        :style="{ top: item.top, left: item.left }"
      >
        <div class="title" :class="index % 2 == 0 && index != 6 ? 'right' : ''">
          <div class="cic" style="" v-if="index % 2 != 0 || index == 6"></div>
          <span>{{
            index == 0
              ? "家政维修"
              : index == 1
              ? "物业缴费"
              : index == 2
              ? "优鲜团"
              : index == 3
              ? "共享车位"
              : index == 4
              ? "优鲜生"
              : index == 5
              ? "优安停"
              : "地球管家"
          }}</span>
          <div class="cic" style="" v-if="index % 2 == 0 && index != 6"></div>
        </div>
        <div class="sub-title">
          <span style="font-size: 28px;margin-right:-10px">￥</span>
          <count-to
            :startVal="oldBase.jzTodayRevenue"
            :endVal="base.jzTodayRevenue"
            :duration="1000"
            v-if="index == 0"
          ></count-to>
          <count-to
            :startVal="oldBase.wyfTodayRevenue"
            :endVal="base.wyfTodayRevenue"
            :duration="1000"
            v-if="index == 1"
          ></count-to>
          <count-to
            :startVal="oldBase.yxtTodayRevenue"
            :endVal="base.yxtTodayRevenue"
            :duration="1000"
            v-if="index == 2"
          ></count-to>
          <count-to
            :startVal="oldBase.cwTodayRevenue"
            :endVal="base.cwTodayRevenue"
            :duration="1000"
            v-if="index == 3"
          ></count-to>
          <count-to
            :startVal="oldBase.yxsTodayRevenue"
            :endVal="base.yxsTodayRevenue"
            :duration="1000"
            v-if="index == 4"
          ></count-to>
          <count-to
            :startVal="oldBase.yatTodayRevenue"
            :endVal="base.yatTodayRevenue"
            :duration="1000"
            v-if="index == 5"
          ></count-to>
          <count-to
            :startVal="oldBase.dqTodayRevenue"
            :endVal="base.dqTodayRevenue"
            :duration="1000"
            v-if="index == 6"
          ></count-to>
        </div>
      </div>
      <div class="main-all" :key="total + 'middle'">
        <div class="num1">今日总营收</div>
        <div class="num2">￥</div>
        <div class="animate__animated animate__heartBeat">
          <count-to
            :startVal="oldTotal"
            :endVal="total"
            :duration="1000"
          ></count-to>
        </div>
      </div>
    </div>
    <div class="today-data">
      <div class="d-title">今日订单</div>
      <div class="item-contaniner">
        <div class="tody-item">
          <div class="title">物业缴费</div>
          <div class="sub-title">
            <count-to
              :startVal="oldBase.wyfTodayOrders"
              :endVal="base.wyfTodayOrders"
              :duration="1000"
            ></count-to>
          </div>
        </div>
        <div class="tody-item">
          <div class="title">家政维修</div>
          <div class="sub-title">
            <count-to
              :startVal="oldBase.jzTodayOrders"
              :endVal="base.jzTodayOrders"
              :duration="1000"
            ></count-to>
          </div>
        </div>
        <div class="tody-item">
          <div class="title">优安停</div>
          <div class="sub-title">
            <count-to
              :startVal="oldBase.yatTodayOrders"
              :endVal="base.yatTodayOrders"
              :duration="1000"
            ></count-to>
          </div>
        </div>
        <div class="tody-item">
          <div class="title">共享车位</div>
          <div class="sub-title">
            <count-to
              :startVal="oldBase.cwTodayOrders"
              :endVal="base.cwTodayOrders"
              :duration="1000"
            ></count-to>
          </div>
        </div>
        <div class="tody-item">
          <div class="title">优鲜生</div>
          <div class="sub-title">
            <count-to
              :startVal="oldBase.yxsTodayOrders"
              :endVal="base.yxsTodayOrders"
              :duration="1000"
            ></count-to>
          </div>
        </div>
        <div class="tody-item">
          <div class="title">优鲜团</div>
          <div class="sub-title">
            <count-to
              :startVal="oldBase.yxtTodayOrders"
              :endVal="base.yxtTodayOrders"
              :duration="1000"
            ></count-to>
          </div>
        </div>
      </div>
      <div class="my-table">
        <div
          v-for="item in tableList"
          :key="item[0] + item[1]"
          class="table-item animation-d"
        >
          <div class="text-ellipsis item1">{{ item[0] }}</div>
          <div class="text-ellipsis item2">{{ item[1] }}</div>
          <div v-if="item[3] != 4"
            class="text-ellipsis item3 abc"
            :class="
              item[3] == 6 || item[3] == 7
                ? 'active-color1'
                : item[3] == 1 || item[3] == 2 || item[3] == 4
                ? 'active-color2'
                : item[3] == 3 || item[3] == 5
                ? 'active-color3'
                : ''
            "
          >
            <div class="num" >付款:{{ item[2] }}元</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CountTo from "vue-count-to";

export default {
  name: "myLeft",
  components: {
    CountTo,
  },
  data() {
    return {
      base: {
        jzTodayRevenue: 0, //今日 家政维修 总营收
        jzTodayOrders: 0, //今日 家政维修 总订单数
        wyfTodayRevenue: 0, //今日 物业缴费 总营收
        wyfTodayOrders: 0, //物业缴费 总订单数
        cwTodayRevenue: 0, //共享车位 总营收
        cwTodayOrders: 0, //共享车位 总订单数
        dqTodayRevenue: 0, //地球管家 总营收
        dqTodayOrders: 0, //地球管家 总订单数
        yatTodayRevenue: 0, //优安停 总营收
        yatTodayOrders: 0, //优安停 总订单数
        yxsTodayRevenue: 0, //优鲜生 总营收
        yxsTodayOrders: 0, //优鲜生 总订单数
        yxtTodayRevenue: 0, //优鲜团 总营收
        yxtTodayOrders: 0, //优鲜团 总订单数
      },
      oldBase: {
        jzTodayRevenue: 0, //今日 家政维修 总营收
        jzTodayOrders: 0, //今日 家政维修 总订单数
        wyfTodayRevenue: 0, //今日 物业缴费 总营收
        wyfTodayOrders: 0, //物业缴费 总订单数
        cwTodayRevenue: 0, //共享车位 总营收
        cwTodayOrders: 0, //共享车位 总订单数
        dqTodayRevenue: 0, //地球管家 总营收
        dqTodayOrders: 0, //地球管家 总订单数
        yatTodayRevenue: 0, //优安停 总营收
        yatTodayOrders: 0, //优安停 总订单数
        yxsTodayRevenue: 0, //优鲜生 总营收
        yxsTodayOrders: 0, //优鲜生 总订单数
        yxtTodayRevenue: 0, //优鲜团 总营收
        yxtTodayOrders: 0, //优鲜团 总订单数
      },
      total: 0,
      oldTotal: 0,
      list: [
        { top: "80px", left: "280px" },
        { top: "120px", left: "1140px" },
        { top: "340px", left: "40px" },
        { top: "340px", left: "1520px" },
        { top: "830px", left: "60px" },
        { top: "950px", left: "750px" },
        { top: "880px", left: "1480px" },
      ],
      tableList: [],
    };
  },
  created() {},
  computed: {},
  methods: {
    init(data) {
      this.oldBase = this.base;
      this.oldTotal =
        this.base.wyfTodayRevenue +
        this.base.cwTodayRevenue +
        this.base.dqTodayRevenue +
        this.base.yatTodayRevenue +
        this.base.yxsTodayRevenue +
        this.base.jzTodayRevenue +
        this.base.yxtTodayRevenue;

      this.total =
        data.wyfTodayRevenue +
        data.cwTodayRevenue +
        data.dqTodayRevenue +
        data.yatTodayRevenue +
        data.yxsTodayRevenue +
        data.jzTodayRevenue +
        data.yxtTodayRevenue;
      this.base = data;
    },
    calcTotal() {},
    initOrder(list, res) {
      if (list) {
        this.tableList = list.slice(0, 4);
      }
      if (res) {
        // console.log(res);
        let tempAmount = 0;
        //新订单来源（1-家政维修 2-物业缴费 3-共享车位 4-地球管家 5-优安停 6-优鲜生 7-优鲜团）
        if (res.source == 1) {
          //1-家政维修
          this.oldBase.jzTodayRevenue = this.base.jzTodayRevenue;
          tempAmount = this.oldBase.jzTodayRevenue;
          this.base.jzTodayRevenue = res.todayAmount;
          this.oldBase.jzTodayOrders = this.base.jzTodayOrders;
          this.base.jzTodayOrders = res.todayOrders;
        } else if (res.source == 2) {
          // 2-物业缴费

          this.oldBase.wyfTodayRevenue = this.base.wyfTodayRevenue;
          tempAmount = this.oldBase.wyfTodayRevenue;
          this.base.wyfTodayRevenue = res.todayAmount;
          this.oldBase.wyfTodayOrders = this.base.wyfTodayOrders;
          this.base.wyfTodayOrders = res.todayOrders;
        } else if (res.source == 3) {
          //3-共享车位

          this.oldBase.cwTodayRevenue = this.base.cwTodayRevenue;
          tempAmount = this.oldBase.cwTodayRevenue;
          this.base.cwTodayRevenue = res.todayAmount;
          this.oldBase.cwTodayOrders = this.base.cwTodayOrders;
          this.base.cwTodayOrders = res.todayOrders;
        } else if (res.source == 4) {
          //4-地球管家  没有金额

          // this.oldBase.dqTodayRevenue = this.base.dqTodayRevenue;
          // tempAmount = this.oldBase.dqTodayRevenue;
          // this.base.dqTodayRevenue = res.todayAmount;
          // this.oldBase.dqTodayOrders = this.base.dqTodayOrders;
          // this.base.dqTodayOrders = res.todayOrders;
        } else if (res.source == 5) {
          //5-优安停

          this.oldBase.yatTodayRevenue = this.base.yatTodayRevenue;
          tempAmount = this.oldBase.yatTodayRevenue;
          this.base.yatTodayRevenue = res.todayAmount;
          this.oldBase.yatTodayOrders = this.base.yatTodayOrders;
          this.base.yatTodayOrders = res.todayOrders;
        } else if (res.source == 6) {
          // 6-优鲜生

          this.oldBase.yxsTodayRevenue = this.base.yxsTodayRevenue;
          tempAmount = this.oldBase.yxsTodayRevenue;
          this.base.yxsTodayRevenue = res.todayAmount;
          this.oldBase.yxsTodayOrders = this.base.yxsTodayOrders;
          this.base.yxsTodayOrders = res.todayOrders;
        } else if (res.source == 7) {
          //7-优鲜团
        
          this.oldBase.yxtTodayRevenue = this.base.yxtTodayRevenue;
            tempAmount = this.oldBase.yxtTodayRevenue;
          this.base.yxtTodayRevenue = res.todayAmount;
          this.oldBase.yxtTodayOrders = this.base.yxtTodayOrders;
          this.base.yxtTodayOrders = res.todayOrders;
        }
        if(res.source!=4){
          this.oldTotal = this.total;
          this.total = this.total - tempAmount + res.todayAmount;
        }
        
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container2 {
  margin: 0px 20px 0 20px;
}
.c1 {
  width: 1762px;
  height: 1270px;
  background-image: url(https://fresh-life.oss-cn-shanghai.aliyuncs.com/app/largeScreen/bg.gif);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  backface-visibility: hidden;
  .image {
    margin-top: 20px;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .main {
    width: 320px;
    height: 100px;
  }
  .main-item {
    -webkit-animation: bounce-down 1.82s linear infinite;
    animation: bounce-down 1.82s linear infinite;
    .cic {
      width: 12px;
      height: 12px;
      background: #f9f0ba;
      border-radius: 50%;
      box-shadow: 0px 0px 34px 8px rgba(255, 185, 0, 0.56);
      margin: 0 14px;
    }
    width: 280px;
    height: 100px;
    font-size: 32px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    letter-spacing: 6px;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: absolute;
    .title {
      width: 280px;
      height: 50px;
      font-size: 34px;
      font-family: Tensentype-LingHeiJ, Tensentype;
      font-weight: normal;
      color: #ffffff;
      line-height: 50px;
      letter-spacing: 6px;
      display: flex;
      align-items: center;

      // margin: 0 18px;

      background: linear-gradient(
        94deg,
        rgba(0, 22, 47, 0) 0%,
        #0153ae 63%,
        rgba(0, 23, 48, 0) 100%
      );
    }
    .sub-title {
      margin-top: 14px;
      margin-left: 8px;
      margin-right: 8px;
      height: 46px;
      font-size: 32px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 6px;
    }
  }
  .main-all {
    width: 630px;
    height: 130px;
    font-size: 48px;
    display: flex;
    font-family: Tensentype-LingHeiJ, Tensentype;
    font-weight: normal;
    color: #feff8e;
    line-height: 130px;
    text-shadow: 0px 4px 8px #013a7e;
    position: absolute;
    top: 58%;
    left: calc(50% - 300px);
    background-image: url("../../../assets/middle-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    -webkit-animation: bounce-down 1.82s linear infinite;
    animation: bounce-down 1.82s linear infinite;
    .num1 {
      padding-left: 140px;
      // padding-left: 16px;
      font-size: 36px;
    }
    .num2 {
      margin-top: 4px;
      font-size: 28px;
    }
  }
  .right {
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
  }
  .left {
    align-items: flex-start;
    text-align: left;
  }
  // background: tr;
}
.item-contaniner {
  margin-top: 26px;
  display: flex;
  height: 120px;
  justify-content: space-between;
  .tody-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 268px;
    height: 110px;
    background-image: url("../../../assets/midde-today-item.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // margin-right: 26px;
    .title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 28px;
      height: 28px;
    }
    .sub-title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #49ddff;
      line-height: 42px;
      height: 42px;
    }
  }
}
.my-table {
  margin-top: 44px;
  width: 1762px;
  height: 390px;
  .table-item {
    display: flex;
    height: 72px;
    background: linear-gradient(180deg, #00142d 0%, #0b294a 100%);
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 72px;
    margin-bottom: 24px;
    animation: fadeInDown 1s;
    .item1 {
      width: 200px;
      text-align: right;
    }
    .item2 {
      flex: 1;
      padding-right: 30px;
    }
    .item3 {
      width: 246px;
    }
  }
}
.abc {
  // width: 190px;
  // padding: 0px 20px;
  height: 52px;
  margin-top: 10px;
  margin-right: 40px;
  line-height: 52px;
  background: #082443;
  padding: 0 18px;
  border: 2px solid;
  text-align: center;
  box-sizing: border-box;
  border-image: linear-gradient(
      270deg,
      rgba(14, 158, 113, 1),
      rgba(22, 241, 191, 1)
    )
    2 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: skew(-25deg);
  .num {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: skew(25deg);
  }
}
.active-color1 {
  border: 2px solid #c98437;
  color: #f1a050;
}
.active-color2 {
  background: #082443;
  border: 2px solid;
  border-image: linear-gradient(
      270deg,
      rgba(14, 158, 113, 1),
      rgba(22, 241, 191, 1)
    )
    2 2;
}
.active-color3 {
  background: #082443;
  border: 2px solid;
  border-image: linear-gradient(
      270deg,
      rgba(0, 149, 255, 1),
      rgba(9, 250, 215, 1)
    )
    2 2;
}
@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(8px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-8px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(8px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-8px);
  }
}
[v-cloak] {
  display: none !important;
}
</style>
